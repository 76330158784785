








import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';

import { Map, Overlay } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import { Coordinate } from 'ol/coordinate';
import OverlayPositioning from 'ol/OverlayPositioning';

export default Vue.extend({
  props: {
    coordinates: {
      type: Array as () => Coordinate,
    },
  },

  data(): {
    id: string;
    overlay?: Overlay;
  } {
    const id = uuidv4();
    return {
      id: id,
      overlay: undefined,
    };
  },

  computed: {
    map(): Map | undefined {
      return (this as any)._getMap();
    },
    layer(): VectorLayer | undefined {
      return (this as any)._getLayer();
    },
  },

  inject: {
    _getMap: {
      default: undefined,
    },
    _getLayer: {
      default: undefined,
    },
  },

  mounted() {
    const element = document.getElementById(this.id) || undefined;
    this.overlay = new Overlay({
      element: element,
      positioning: OverlayPositioning.TOP_LEFT,
      stopEvent: false,
    });
  },

  watch: {
    overlay: {
      immediate: false,
      handler() {
        if (this.overlay) {
          this.map?.addOverlay(this.overlay);
        }
      },
    },

    coordinates: {
      immediate: true,
      handler() {
        const overlayRect = this.overlay?.getElement()?.getBoundingClientRect();
        const mapRect = this.map?.getTargetElement().getBoundingClientRect();
        if (overlayRect && mapRect) {
          // TODO: compare overlay and map to get an offset if it is located  outside the screen
          this.overlay?.setOffset([0, 0]);

          this.overlay?.setPosition(this.coordinates);
        }
      },
    },
  },

  destroyed() {
    if (this.overlay) {
      this.map?.removeOverlay(this.overlay);
    }
  },
});
